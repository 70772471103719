


import { gsap, TweenLite, TimelineMax, Linear, Back, Sine, Power2, Power4, TimelineLite } from 'gsap'; // GSAP
import { ScrollTrigger, ScrollSmoother } from "gsap/ScrollTrigger"; // ScrollTrigger
import Swiper from 'swiper'; // Swiper
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { $, jQuery } from 'jquery';


var menuAnimation = new TimelineMax({ paused: true });
var menuAnimationBack = new TimelineMax({ paused: true, reversed: true });
var navMain = document.getElementById("collapsible-nav");
var menuButton = document.getElementById("nav-toggler");
    
var canSlideCarousel;

var toggle = true;
var togglw = true;
    
let start = 1500;
let middleAnimationDuration = 3000;

window.addEventListener("load", function() {

    gsap.registerPlugin(ScrollTrigger);
    navUnderline();
    badgeCarousel();
    textReveal();
    reveal();
    animation();
    testimonialCarousel();


    var menuAnimation = new TimelineMax({paused:true});
    var menuAnimationBack = new TimelineMax({paused:true, reversed: true});
    var navMain = document.getElementById("collapsible-nav");
    var menuButton = document.getElementById("nav-toggler");
    var toggle = true;
    var togglw = true;
    var mqls = [
        window.matchMedia("(min-width: 769px)"),
        window.matchMedia("(min-width: 581px) and (max-width: 768px)"),
        window.matchMedia("(max-width: 580px)")
    ]

    function mediaqueryresponse(mql){
        if (mqls[0].matches){
            menuAnimation
                .to(navMain, 0.1, {width: 480, className : "+=vertical", ease: Power2.easeInOut, transform: "translate3d(0,0,0)"},0);
        }
        if (mqls[1].matches){
            menuAnimation
                .to(navMain, 0.1, {width: '50%', className : "+=vertical", ease: Power2.easeInOut, transform: "translate3d(0,0,0)"},0);
        }
        if (mqls[2].matches){
            menuAnimation
                .to(navMain, 0.2, {width: '100%', className : "+=vertical", ease: Power2.easeInOut, transform: "translate3d(0,0,0)"},0);
        }
    }

    for (var i=0; i<mqls.length; i++){
        mediaqueryresponse(mqls[i])
        mqls[i].addListener(mediaqueryresponse)
    }

    menuAnimationBack
        .to(navMain, 0.55, {width: 0, className : "+=skewback", ease: Power4.easeIn, transform: "translate3d(0,0,0)"},0);

    menuButton.onclick = function() {
        toggle = !toggle;
        toggle === false ? menuAnimation.play(0) : menuAnimationBack.play(0);
        toggle === false ? document.getElementById('platy-navbar').classList.add('expand') : document.getElementById('platy-navbar').classList.remove('expand');
    };


    const Newsletter = require("./forms/newsletter");


    const newsletterForm = document.getElementById('newsletter');
    newsletterForm.addEventListener('submit', async (event) => {
        event.preventDefault();
        const Newsletter = require('./forms/newsletter');
        Newsletter.store(event);
    });
    
});


function animation() {
    
ScrollTrigger.matchMedia({
  "(min-width: 1180px)": function() {

    var animatingScroll = false;

    gsap.timeline({
    paused: true,
    scrollTrigger: {
        trigger: "#animated-scroll",
        start: "top top",
        end: "+=6000",
        scrub: true,
        pin: true,
        onLeave:  toggleButton
    }
    })
    .to("#ioUou",  { x: window.innerWidth/1.5, y: 0, opacity: 0, scrollTrigger: { start: 2000, end: 4500, scrub: 1 } }, 0)
    .to(".CmAct",  { opacity: 0, scrollTrigger: { start: 3500, end: 5000, scrub: 1 }}, 0)
    .to("#QFGWa",  { y: -308, scrollTrigger: { start: 4500, end: 5000, scrub: 1 } }, 0)
    .to("#lwIfz",  { y: 200, scrollTrigger: { start: 5000, end: 6000, scrub: 1 } }, 0)
    .to("#lwIfz",  { opacity: 1, scrollTrigger: { start: 5750, end: 6000, scrub: 1 } }, 0)
    .to("#EJkhZ",  { y: 200, scrollTrigger: { start: 5000, end: 6000, scrub: 1 } }, 0)
    .to("#EJkhZ",  { opacity: 1, scrollTrigger: { start: 5750, end: 6000, scrub: 1 } }, 0)
    .to("#isyuu",  { opacity: 1, display: "block", scrollTrigger: { start: 5500, end: 6000, scrub: 1 } }, 0)
    .to("#aTvVd",  { opacity: 1, display: "block", scrollTrigger: { start: 5500, end: 6000, scrub: 1 } }, 0)
    .to("#QIlrq",  { display: "block",  onComplete: toggleButton, scrollTrigger: { start: 5500, end: 6000, scrub: 1 } }, 0);
}
});
}

function toggleButton() {
 document.getElementById('QIlrq').classList.add('show');
}

/**
* Nav Underline
*/

function navUnderline() {

    var follow = {};

    follow.ul = document.querySelector('#platy-nav');
    follow.links = document.querySelectorAll('.nav-item');
    follow.dropdowns = document.querySelectorAll('.dropdown');
    follow.focusedLink = document.getElementById('menu-item-616');
    follow.amountScrolled = window.scrollY;
    follow.amountToPushUnderlineDown = -14;
    follow.pushUnderlineDown = follow.amountScrolled + follow.amountToPushUnderlineDown;
    follow.timeout;


    // build underline element
    follow.createUnderline = function () {
        follow.underline = document.createElement('span');
        follow.underline.setAttribute('id', 'nav-underline');
        follow.underline.style.display = 'inline-block';
        follow.underline.style.borderBottom = '0';
        follow.underline.style.display = 'inline-block';
        follow.underline.style.position = 'absolute';
        follow.underline.style.transition = 'all .5s';
        follow.ul.append(follow.underline);
    };

    // add underline to the .focused-link element
    follow.addUnderlineToFocusedLink = function () {
        setTimeout(function () {
            follow.underline.classList.add('static');
            follow.underline.classList.remove('changing');
        }, 300);
    };

    follow.placeUnderlineOnTarget = function (target) {
        follow.underline.style.width = target.width + 'px';
        follow.underline.style.left = target.left + 'px';
        follow.underline.style.top = target.bottom + 'px';
        follow.underline.classList.add('changing');
        follow.underline.classList.remove('static');
    }

    follow.addUnderlineToTargetLink = function () {
        follow.links.forEach(function (link) {
            link.addEventListener('mouseover', function () {
                clearTimeout(follow.timeout);
                const target = link.getBoundingClientRect();
                follow.underline.style.display = 'inline-block';
                follow.placeUnderlineOnTarget(target);
            });

            if (follow.focusedLink) {
                link.addEventListener('mouseout', function () {
                    follow.timeout = setTimeout(function () {
                        follow.addUnderlineToFocusedLink();
                    }, 385);
                });
            }
        });
    };

    // update position of underline to remain under .focused-link element
    follow.updatePositionOnResize = function () {
        window.addEventListener('resize', function () {
            if (follow.focusedLink) {
                follow.addUnderlineToFocusedLink();
            } else {
                follow.underline.style.display = 'none';
            }
        });
    };

    // update position of underline to remain under .focused-link element
    follow.updatePositionOnScroll = function () {
        window.addEventListener('scroll', function (e) {
            follow.amountScrolled = this.scrollY;
            follow.pushUnderlineDown = follow.amountScrolled + follow.amountToPushUnderlineDown;
            follow.addUnderlineToFocusedLink();
        });
    };

    follow.init = function () {

        follow.createUnderline();

        if (follow.focusedLink) {
            follow.addUnderlineToFocusedLink();
        }

        follow.updatePositionOnResize();
        follow.updatePositionOnScroll();
        follow.addUnderlineToTargetLink();
    };

    follow.init();
}

/**
* Text Reveal
*/

function textReveal() {
    var tl = new TimelineLite({ delay: 1 }),
        firstBg = document.querySelectorAll('.text__first-bg'),
        secBg = document.querySelectorAll('.text__second-bg'),
        word = document.querySelectorAll('.text__word'),
        second = document.querySelectorAll('.text__second');
    tl
        .to(firstBg, 0.2, { scaleX: 1 })
        .to(secBg, 0.2, { scaleX: 1 })
        .to(second, 0.2, { background: 'transparent' })
        .to(word, 0.1, { opacity: 1 }, "-=0.1")
        .to(firstBg, 0.2, { scaleX: 0 })
        .to(secBg, 0.2, { scaleX: 0 });
}


/**
* Reveal
*/
function reveal() {
    const animateFrom = (elem, direction = 1) => {
        let x = 0,
            y = direction * 50;
        if (elem.classList.contains("gsRevealFromLeft")) {
            x = -50;
            y = 0;
        } else if (elem.classList.contains("gsRevalFromRight")) {
            x = 50;
            y = 0;
        }

        gsap.fromTo(
            elem,
            { x, y, autoAlpha: 0 },
            {
                duration: 3,
                x: 0,
                y: 0,
                autoAlpha: 1,
                ease: "expo",
                overwrite: "auto"
            }
        );
    };

    const hide = elem => gsap.set(elem, { autoAlpha: 0 });
    gsap.utils.toArray(".gsReveal").forEach(elem => {
        hide(elem);
        ScrollTrigger.create({
            trigger: elem,
            onEnter: () => animateFrom(elem),
            onEnterBack: () => animateFrom(elem, -1),
            onLeave: () => hide(elem)
        });
    });
}



/**
* Carousels
*/

function badgeCarousel() {

    var featuresCarousel = new Swiper(".features-carousel", {
        direction: "horizontal",
        observer: true,
        observeParents: true,
        speed: 800,
        slidesPerView: 5,
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,
        lazy: true,
        grabCursor: true,
        mousewheel: true,
        breakpoints: {
            0: {
                lazy: true,
                slidesPerView: 1,
                spaceBetween: 20,
            },
            300: {
                lazy: true,
                slidesPerView: 2,
                spaceBetween: 20,
            },
            600: {
                lazy: true,
                slidesPerView: 3,
                spaceBetween: 20,
            },
            900: {
                lazy: true,
                slidesPerView: 4,
                spaceBetween: 20,
            },
            1200: {
                lazy: true,
                slidesPerView: 5,
                spaceBetween: 20,
            },
            1400: {
                lazy: true,
                slidesPerView: 5,
                spaceBetween: 20,
            },
            2400: {
                lazy: true,
                slidesPerView: 6,
                spaceBetween: 20,
            }
        },
        on: {
            slideNextTransitionStart: function () {
                canSlideCarousel = 0;
                document.querySelector('.platy-features-carousel').classList.add('disabled');
            },
            slidePrevTransitionStart: function () {
                canSlideCarousel = 0;
                document.querySelector('.platy-features-carousel').classList.add('disabled');
            },
            slideNextTransitionEnd: function () {
                canSlideCarousel = 1;
                document.querySelector('.platy-features-carousel').classList.remove('disabled');
            },
            slidePrevTransitionEnd: function () {
                canSlideCarousel = 1;
                document.querySelector('.platy-features-carousel').classList.remove('disabled');
            }
        }

    });

    const badgeCarousel = new Swiper("#badge-carousel", {
        direction: "horizontal",
        observer: true,
        observeParents: true,
        speed: 1200,
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,
        grabCursor: true,
        keyboard: {
            enabled: true
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            300: {
                centeredSlides: true,
                slidesPerView: 1,
                spaceBetween: 20,
            },
            600: {
                centeredSlides: true,
                slidesPerView: 1,
                spaceBetween: 20,
            },
            900: {
                centeredSlides: true,
                slidesPerView: 1,
                spaceBetween: 20,
            },
            1200: {
                centeredSlides: true,
                slidesPerView: 1,
                spaceBetween: 20,
            },
            1400: {
                centeredSlides: true,
                slidesPerView: 1,
                spaceBetween: 20,
            },
            2400: {
                centeredSlides: true,
                slidesPerView: 1,
                spaceBetween: 20,
            },
        },
        on: {
            slideNextTransitionStart: function (sw) {
                if (canSlideCarousel == 0) {
                } else {
                    featuresCarousel.slideNext();
                }
            },
            slidePrevTransitionStart: function (sw) {
                if (canSlideCarousel == 0) {
                } else {
                    featuresCarousel.slidePrev();
                }
            },
        }
    });

    setInterval(() => {
        badgeCarousel.slideNext();
    }, 5000);

}   

function testimonialCarousel() {

    var testimonials = new Swiper("#testimonial-carousel", {
        slidesPerView: "auto",
        spaceBetween: 30,
        centeredSlides: false,
        observer: true,
        observeParents: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });

    if (document.getElementById('testimonial-carousel-prev')) {
        document.getElementById('testimonial-carousel-prev').addEventListener("click", function () {
            testimonials.slidePrev();
        });
    }
    if (document.getElementById('testimonial-carousel-next')) {
        document.getElementById('testimonial-carousel-next').addEventListener("click", function () {
            testimonials.slideNext();
        })
    }
}