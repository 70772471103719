    import * as Yup from 'yup'
    import axios from 'axios';

    class Newsletter {

        async store(e) {

            const email = e.target.elements.email.value;
            
            await axios({
                method: 'POST',
                url: `https://api.hsforms.com/submissions/v3/integration/submit/9366245/3f138a1a-c71a-4a87-b234-e0d9330c338e/`,
                data : {
                    "fields" : [{
                        "name":"email",
                        "value":email
                    }]
                }
            })
            .then((response) => {
                document.getElementById('form--newsletter').innerHTML = response.data.inlineMessage;

            })
            .catch((error) => {
                document.getElementById('form-error--newsletter').innerHTML = "Something went wrong, please try again.";
            });
        }
    }

    module.exports = new Newsletter();